<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      width="600px"
      @cancel="cancel"
      @confirm="confirm"
      @openDialog="openDialog"
    />
    <!-- 弹出表格 -->
    <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="tableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :config="dialogConfig"
      :defaultParams="dialogDefaultParams"
      :multiple="true"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
    </dialog-table-list>
  </div>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {
        type: 'SYSTEM_NOTICE',
      },
      config: [
        // lang:消息群体
        {
          tag: 'el-select',
          label: this.$t('messageManage.target'),
          prop: 'target',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: this.$dict.messageTarget(),
          },
          tagEvents: {
            change(e) {
              if (e === 'USER_ACCOUNT') {
                self.config[1].hidden = false
              } else {
                self.config[1].hidden = true
              }
            },
          },
        },
        // 推送账号
        {
          tag: 'dialog-define',
          label: this.$t('messageManage.userAccountIdSet'),
          prop: 'userAccountIdSet',
          hidden: true,
          rules: {
            noNull: true,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              if (!data || !data.length) return ''
              return data.map((item) => item.name).join(',')
            },
          },
        },
        // lang:产品类型
        {
          tag: 'el-select',
          label: this.$t('messageManage.type'),
          prop: 'product',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            props: {
              label: 'value',
              value: 'id',
            },
          },
        },
        // lang:消息标题
        {
          label: this.$t('messageManage.title'),
          prop: 'title',
          rules: {
            noNull: true,
          },
        },
        // lang:消息内容
        {
          label: this.$t('messageManage.content'),
          prop: 'content',
          rules: {
            noNull: true,
          },
          tagProps: {
            type: 'textarea',
            rows: 4,
          },
        },
      ],
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: [],
    }
  },
  mounted() {
    this.setDefaultValue()
    this.getFindProduct()
  },
  methods: {
    // 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
    },
    // 打开表格弹框
    openDialog(item) {
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || []
      this.dialogTitle = this.$t('messageManage.userAccountIdSet')
      this.dialogUrl = '/core' + this.$api.userAccountFind
      this.dialogDefaultParams = {
        companySnStartWith: this.$store.getters.company.sn,
      }
      const self = this
      this.dialogColumns = [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
          callback: (row) => {
            return this.$util.format(row.createTime, 'yyyy-MM-dd')
          },
        },
        // lang:所属部门
        {
          label: this.$t('adminAccount.accountGroup'),
          prop: 'accountGroup',
          callback(row) {
            return row.accountGroup && row.accountGroup.name
          },
        },
        // lang:姓名
        {
          label: this.$t('adminAccount.name'),
          prop: 'name',
        },
        // lang:手机号
        {
          label: this.$t('adminAccount.mobile'),
          prop: 'mobile',
        },
        // lang:登录名
        {
          label: this.$t('adminAccount.loginName'),
          prop: 'accountId',
          width: '180px',
        },
        // lang:账号类型
        {
          label: this.$t('adminAccount.type'),
          prop: 'type',
          callback(row) {
            const accountTypeObj = self.$util.listToObj(
              self.$dict.userAccountType()
            )
            return accountTypeObj[row.type]
          },
        },
        // lang:昵称
        {
          label: this.$t('adminAccount.nickname'),
          prop: 'nickname',
        },
      ]
      this.dialogConfig = [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 8,
          tagProps: {
            placeholder: this.$t('adminAccount.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 11,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ]
      this.showDialogTableList = true
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.tableList.searchList(dialogFormData)
    },
    //
    getFindProduct() {
      this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findProductTypeList,
        }).then((data) => {
          data.forEach(item => {
            if(item.value === 'TMVA'){
              item.value = '远程指导'
            }
            if(item.value === 'XR_WORK'){
              item.value = '工作流'
            }
          })
          this.config[2].tagProps.options = data
          console.log(data)
        })
    },

    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      if (formData.target === 'USER_ACCOUNT') {
        formData.userAccountIdSet = formData.userAccountIdSet.map(
          (item) => item.id
        )
      } else {
        delete formData.userAccountIdSet
      }

      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      if(formData.product === '工作流') formData.product = 'XR_WORK'
      if(formData.product === '远程指导') formData.product = 'TMVA'
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.messageAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$emit('closePage', 'add')
      })
    },
  },
}
</script>
