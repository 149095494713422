<template>
  <base-page
    :title="$t('messageManage.pageTitle')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
  >
    <table-list
      ref="tableList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.messageFind"
      :columns="columns"
      :defaultParams="defaultParams"
      v-model="tableRow"
    >
      <!-- 推送 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd hh:mm') }}
      </template>
      <!-- 消息群体 -->
      <template v-slot:target="{ row }">
        {{ messageTargetObj[row.target] }}
      </template>
      <!-- 是否已撤回 -->
      <template v-slot:rollbackStatus="{ row }">
        {{ yesOrNoObj[row.rollbackStatus] }}
      </template>
      <!-- 操作 -->
      <template v-slot:option="{ row }">
        <el-button
          type="text"
          v-if="row.target === 'USER_ACCOUNT'"
          @click="openAccountDialog(row)"
        >
          {{ $t('messageManage.seeAccount') }}
        </el-button>
        <el-button
          type="text"
          v-if="row.rollbackStatus === 'NO'"
          @click="rollbackMethod(row)"
        >
          {{ $t('functionEnum.MESSAGE_ROLLBACK') }}
        </el-button>
      </template>
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
    <dialog-table-list
      :title="$t('messageManage.seeAccount')"
      v-if="showAccountDialog"
      :isDefine="true"
      :defaultContent="defaultContent"
      :columns="dialogColumns"
      :config="[]"
      :showConfirm="false"
      @cancel="showAccountDialog = false"
    >
    </dialog-table-list>
  </base-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    const self = this
    return {
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 10,
          tagProps: {
            placeholder: this.$t('messageManage.searchTitle'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 10,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      defaultParams: {
        companyId: this.$store.getters.company.id,
      },
      messageTargetObj: this.$util.listToObj(this.$dict.messageTarget()),
      yesOrNoObj: this.$util.listToObj(this.$dict.yesOrNo()),
      columns: [
        // lang:推送时间
        {
          label: this.$t('messageManage.createTime'),
          prop: 'createTime',
        },
        // lang:消息群体
        {
          label: this.$t('messageManage.target'),
          prop: 'target',
        },
        // lang:消息标题
        {
          label: this.$t('messageManage.title'),
          prop: 'title',
        },
        // lang:消息内容
        {
          label: this.$t('messageManage.content'),
          prop: 'content',
        },
        // lang:产品类型
        {
          label: this.$t('messageManage.type'),
          prop: 'product',
          callback(row){
            if(row.product === 'XR_WORK') return '工作流'
            if(row.product === 'TMVA') return '远程指导'
          }
        },
        // lang:是否已撤回
        {
          label: this.$t('messageManage.rollbackStatus'),
          prop: 'rollbackStatus',
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
        },
      ],
      // 表单页
      showDetail: false,
      // 推送账户
      showAccountDialog: false,
      defaultContent: [],
      dialogColumns: [
        // lang:创建时间
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
          callback: (row) => {
            return this.$util.format(row.createTime, 'yyyy-MM-dd')
          },
        },
        // lang:所属部门
        {
          label: this.$t('adminAccount.accountGroup'),
          prop: 'accountGroup',
          callback(row) {
            return row.accountGroup && row.accountGroup.name
          },
        },
        // lang:姓名
        {
          label: this.$t('adminAccount.name'),
          prop: 'name',
        },
        // lang:手机号
        {
          label: this.$t('adminAccount.mobile'),
          prop: 'mobile',
        },
        // lang:登录名
        {
          label: this.$t('adminAccount.loginName'),
          prop: 'accountId',
          width: '180px',
        },
        // lang:账号类型
        {
          label: this.$t('adminAccount.type'),
          prop: 'type',
          callback(row) {
            const accountTypeObj = self.$util.listToObj(
              self.$dict.userAccountType()
            )
            return accountTypeObj[row.type]
          },
        },
        // lang:昵称
        {
          label: this.$t('adminAccount.nickname'),
          prop: 'nickname',
        },
      ],
    }
  },
  methods: {
    // 查看推送账户
    openAccountDialog(row) {
      this.$ajax({
        url: '/core' + this.$api.userAccountFind,
        data: {
          includeIdSet: row.userAccountIdStr.split(','),
        },
      }).then((data) => {
        this.defaultContent = data
        this.showAccountDialog = true
      })
    },
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      this.$refs.tableList.searchList(this.formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
          this.showDetail = true
          break
        case 'del':
          this.deleteMethod()
          break
        default: // do something
      }
    },
    // 删除
    deleteMethod() {
      this.$element
        .showConfirm({
          content: this.$t('base.deleteConfirm'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.messageDelete,
            data: this.tableRow,
          }).then(() => {
            // lang:删除成功
            this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
            this.search()
          })
        })
    },
    // 撤回
    rollbackMethod(row) {
      this.$element
        .showConfirm({
          content: this.$t('base.confirmRollback'),
        })
        .then(() => {
          this.$ajax({
            url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.messageRollback,
            data: row,
          }).then(() => {
            // lang:撤回成功
            this.$element.showMsg(this.$t('base.rollbackSuccess'), 'success')
            this.search()
          })
        })
    },
  },
}
</script>
